/* Reset some default styles */
body, h1, h2, h3, p, ul, li {
  margin: 0;
  padding: 0;
}

/* Global styles */
body {
  font-family: Arial, sans-serif;
}

/* Header styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  background-color: #000000;
  color: #fff;
}

.logo {
  font-size: 20px;
}

.nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
}

.nav a {
  text-decoration: none;
  color: #fff;
}

.auth-buttons {
  display: flex;
  gap: 10px;
}

.btn {
  padding: 8px 12px;
  border: none;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

/* Content area styles */
.content {
  padding: 20px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .nav {
    display: none;
  }
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.centered-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 500px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
}

/* Add these styles to your existing CSS or a new stylesheet */
.google-button {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  border: 1px solid #ccc; /* Add border style */
  border-radius: 5px;
  background-color: #fff;
  color: #333;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s; /* Add border-color transition */
}

.google-icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.logoimage {
  height: 150px;

}

.google-button:hover {
  background-color: #f1f1f1;
  color: #222;
  border-color: #999; /* Change border color on hover */
}

.forgot-password {
  margin-top: 10px;
  font-size: 12px; /* Adjust the font size as needed */
}

/* ... Layout ... */

..layout {
  flex: 1; /* Allow layout to take up available vertical space */
  display: flex;
  flex-direction: column;
}

.top-bar {
  height: 155px;
  background-color: #000000; /* Dark grey color */
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; /* Add z-index to ensure the top bar is above other content */
}

.top-bar-content {
  color: white; /* Text color */
  font-size: 14px;
}

.bottom-bar {
  height: 20px;
  background-color: #000000; /* Dark grey color */
  display: flex;
  justify-content: flex-end; /* Align content to the right */
  align-items: center; /* Center content vertically */
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000; /* Add z-index to ensure the bottom bar is above other content */
}

.bottom-bar-content {
  color: white; /* Text color */
  font-size: 12px;
}

.page-content {
  flex: 1; /* Take up available vertical space */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  padding-top: 40px; /* Add padding to accommodate the fixed top bar */
  padding-bottom: 40px; /* Add padding to accommodate the fixed bottom bar */
  max-height: calc(100vh - 40px - 40px); /* Subtract top and bottom bar heights */
  overflow: hidden; /* Hide overflow content */
}

/* Sample CSS for active navigation link */
.navigation-frame {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  padding: 0px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.navigation-frame a {
  margin: 0 20px;
  text-decoration: none;
  color: #ffffff;
  font-weight: normal;
  font-size: 22px;
}

.navigation-frame a.active {
  /*font-weight: bold;*/
  background-color:#005800;
}