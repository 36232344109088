.exercise-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ccc;
  }
  
  .exercise-table th,
  .exercise-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ccc;
  }
  
  .exercise-table th {
    background-color: #f2f2f2;
  }
  
  .exercise-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .exercise-table tbody tr:hover {
    background-color: #e0e0e0;
  }
  
  /* Add a class for the delete button */
  .delete-button {
    background-color: red;
    color: white;
    border: none;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    font-size: 14px;
    cursor: pointer;
    margin: 0 auto; /* Center horizontally */
    display: flex;
    align-items: center; /* Vertically center content */
  }

/* Style the delete button on hover */
.delete-button:hover {
  background-color: darkred;
}